import React, { FC, Fragment, useEffect } from 'react';
import Header from '../../../components/common/Header';
import Footer from '../../../components/common/Footer';
import Button from '../../../components/common/Button';
import SectionHeader from '../../../components/pagesComponents/HowWeWork/SectionHeader';
import arrowDown from '../../../assets/img/arr-down.png';
import TextImage from '../../../components/pagesComponents/WorkingTogether/TextImage';
import letter from '../../../assets/img/intellectualProperty/letter.svg';
import ip from '../../../assets/img/intellectualProperty/ip.jpg';
import security from '../../../assets/img/intellectualProperty/security.svg';
import whiteBoard from '../../../assets/img/intellectualProperty/whitboard.png';
import fleshka from '../../../assets/img/intellectualProperty/flashdrive.png';
import { Link } from 'react-scroll';
import './intellectualProperty.scss';
import Accordion from '../../../components/pagesComponents/IntellectualProperty/Accordion';
import TextDescription from '../../../components/common/TextDescription';
import SectionTitle from '../../../components/common/SectionTitle';
import FixedButton from '../../../components/common/FixedButton';
import SEO from '../../../components/SEO';

const intellectualProperty: FC = () => {
    let offsetValue: number = -100;

    useEffect(() => {
        const handleOffset = (): void => {
            if (window.innerWidth <= 500) {
                offsetValue = -510;
            }

            if (window.innerWidth <= 950) {
                offsetValue = -1000;
            }
        };
    }, []);

    return (
        <Fragment>
            <SEO
                pageTitle="Blackbird Lab - Your IP Protection"
                pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/how-we-work/intellectual-property.png"
            />
            <Header path="Your IP Protection" />
            <div className="intellectual-property-section">
                <div className="intellectual-property">
                    <SectionHeader
                        typeValue="section"
                        subtitleTextF="Protecting Your"
                        titleText="Intellectual Property"
                        large="large"
                    />
                    <TextDescription>
                        Regardless of how you manage your intellectual property (IP next), we will
                        make sure that you are protected at max. As your off-site team, we created
                        and validated our own security measures and also mastered proven industry
                        standards. We will make sure that your lawyers have all the weaponry to
                        protect you from patent trolls and competitors in or out of your country.
                    </TextDescription>
                    <div className="page-header__button-wrapper page-header__button-wrapper--ip">
                        <Link to="ip" smooth={true} offset={offsetValue}>
                            <Button icon={arrowDown} type="primary" desc="Show how">
                                Show how
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="intellectual-property__text-wrapper">
                    <div className="intellectual-property__text">
                        <SectionTitle
                            type="lighter"
                            title="More than just local IP Due Diligence process"
                        />

                        <TextDescription subtype="mb">
                            Your NDA is backed up by our localized NDA version that is specifically
                            tailored to local laws of any involved parties and subcontractors from
                            our side.
                        </TextDescription>

                        <TextDescription subtype="mb">
                            Each of your extension team members signs it before he or she starts
                            working with any sensitive information you share. You will receive
                            localized NDA and its notarized translation right to your post box.
                        </TextDescription>

                        <TextDescription>
                            However, we are not stopping there. We will also provide you with a
                            proper Assignment of Intellectual Property Rights agreement and
                            Certificate of Originality for all the product features we work on. So
                            you could be the first who files a patent application (if you decide
                            to).
                        </TextDescription>
                    </div>
                    <TextImage id="ip" image={letter} imgDescription="letter" />
                </div>
                <div className="intellectual-property__text-wrapper intellectual-property__text-wrapper--desktop-reverse">
                    <TextImage id="ip" image={ip} imgDescription="ip" relative="resized" />
                    <div className="intellectual-property__text">
                        <SectionTitle type="lighter" title="Best-in-class IP protection tools" />
                        <TextDescription subtype="mb">
                            At Blackbird Lab, we take great pride in providing all our clients with
                            the best IT security setup available, ensuring your valuable assets are
                            shielded from potential threats. With our comprehensive approach, we
                            leverage the robust capabilities of Apple Business Manager(ABM) and
                            Mosyle. ABM streamlines device enrollment, configuration, and
                            management, enabling us to establish a standardized and secure setup
                            across all devices. Within Mosyle, we employ over 50 meticulously chosen
                            policies that fortify your IT security framework. These policies
                            encompass encryption and authentication protocols, data backup
                            strategies, device tracking, and more. Our setup enables us to react
                            swiftly and decisively in the unfortunate event of hardware loss,
                            guaranteeing that sensitive information remains confidential and
                            inaccessible to unauthorized individuals. We are committed to providing
                            the highest level of IT security, ensuring maximum protection and peace
                            of mind.
                        </TextDescription>
                    </div>
                </div>
                <div className="intellectual-property__wrapper intellectual-property__wrapper--center">
                    <div className="intellectual-property__text intellectual-property__text--large">
                        <SectionTitle type="lighter" title="Compliance" subtype="lighter-small" />
                        <TextDescription subtype="smaller">
                            Here we will go through both compliances we follow and ones we will help
                            you to meet. As a result, you are keeping under control all government
                            regulations. Plus, people who use your SaaS product or service are fully
                            protected.
                        </TextDescription>
                    </div>
                </div>
                <div className="intellectual-property__accordion">
                    <Accordion
                        title="CCPA"
                        text="The California Consumer Privacy Act of 2018 (CCPA) gives Californian residents almost ultimate control over their personal information that you collect about them. Even a single newsletter opt-in form could become a nightmare with lots of devastating lawsuits."
                        desc="We at Blackbird designed and developed a special solution that embeds to the back end of your product. It is going to be a one-click-job to collect and export collected user’s personal information in a neat PDF file or delete it all upon request."
                    />
                    <Accordion
                        title="GDPR"
                        text="The General Data Protection Regulation 2016/679 is a regulation in EU law on data protection and privacy in the European Union and the European Economic Area. It also addresses the transfer of personal data outside the EU and EEA areas. "
                        desc="We at Blackbird designed and developed a special solution that embeds to the back end of your product. It is going to be a one-click-job to collect and export collected user’s personal information in a neat PDF file or delete it all upon request."
                    />
                    <Accordion
                        title="ISO 27001"
                        text="ISO/IEC 27001 is an international standard on how to manage information security. The standard was originally published jointly by the International Organization for Standardization and the International Electrotechnical Commission in 2005 and then revised in 2013."
                        desc="We at Blackbird use modern MDM solutions to manage our hardware and train our employees regularly. We are advancing our ways of working and planning to become ISO 27001 compliant in the nearest future."
                    />
                </div>
                <div className="intellectual-property__body">
                    <div className="intellectual-property__text-wrapper">
                        <div className="intellectual-property__text">
                            <SectionTitle type="primary" title="Organization security" />
                            <TextDescription>
                                We assertively follow all the above compliances and standards.
                                Nevertheless, security is not a one time job, it’s a continuous
                                effort to regularly skill up employees, carefully manage IT assets,
                                and to contain top operational security years around.
                            </TextDescription>
                        </div>
                        <TextImage image={security} position="centered" imgDescription="security" />
                    </div>

                    <div className="intellectual-property__text-wrapper intellectual-property__text-wrapper--desktop-reverse">
                        <div className="intellectual-property__img">
                            <TextImage
                                image={whiteBoard}
                                shadow="shadow"
                                imgDescription="White board"
                                position="centered"
                            />
                        </div>

                        <div className="intellectual-property__text intellectual-property__text--fix-width intellectual-property__text--relative">
                            <SectionTitle type="primary" title="Continuous security training" />
                            <TextDescription subtype="mb">
                                We believe that information security begins with educated and
                                well-trained people. None of the information security policies will
                                ever properly work without them.
                            </TextDescription>
                            <TextDescription>
                                It is required to attend monthly security meetups and annual
                                security training for all of our current and new employees. However,
                                our developers have to take even more of such training programs.
                                People who work with your intellectual property must know how to
                                protect it.
                            </TextDescription>
                        </div>
                    </div>

                    <div className="intellectual-property__text-wrapper intellectual-property__text-wrapper--reverse">
                        <div className="intellectual-property__img">
                            <TextImage
                                image={fleshka}
                                shadow="shadow"
                                imgDescription="Fleshka"
                                position="margin-left"
                            />
                        </div>
                        <div className="intellectual-property__text intellectual-property__text--fix-width">
                            <SectionTitle
                                type="primary"
                                title="IT asset management and operational security"
                            />
                            <TextDescription subtype="mb">
                                Blackbird is focused on keeping control of its IT inventory. We
                                established and automatically up-to-date list of our hardware,
                                software, produced and provided data, and other assets like our
                                networks, employee devices, and more. Even such details as connected
                                removable media, USB port activities can be tracked on demand.
                            </TextDescription>
                        </div>
                    </div>
                </div>
            </div>
            <FixedButton />
            <Footer />
        </Fragment>
    );
};

export default intellectualProperty;

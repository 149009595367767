import React, { FC, useState } from 'react';
import { TAccordionProps } from '../../../../types/types';

import showIcon from '../../../../assets/img/workingTogether/show-icon.svg';

import './Accordion.scss';

const Accordion: FC<TAccordionProps> = (props) => {
    const [icon, setIcon] = useState<boolean>(true);
    const toggleIcon = () => {
        setIcon(!icon);
    };

    return (
        <div data-testid="accordion" className="accordion">
            <div className="accordion__wrapper">
                <div className="accordion__head" onClick={toggleIcon}>
                    <h3 className="accordion__title">{props.title}</h3>
                    <img
                        className={
                            icon ? 'accordion__icon' : 'accordion__icon accordion__icon--close'
                        }
                        src={showIcon}
                        alt="Plus"
                    />
                </div>
                <div
                    className={
                        icon
                            ? 'accordion__text-wrapper'
                            : 'accordion__text-wrapper accordion__text-wrapper--show'
                    }
                >
                    <p className="accordion__text">{props.text}</p>
                    <p className="accordion__text">{props.desc}</p>
                </div>
            </div>
        </div>
    );
};

export default Accordion;
